import React, { Component } from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import TextContainer from '../TextContainer';
import { db } from '../../Firebase';
import { Icon, Button } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';

import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Imagetext from '../Imagetext';
import ImagetextLeft from '../ImagetextLeft';
import Beautifulimage from '../Beatifulimage';


const images = [
    {
        original: 'images/dicegame-gamemode2.png',
    },
    {
        original: 'images/dicegame-homescreen2.png',
    },
    {
        original: 'images/dicegame-dices.png',
    },
    {
        original: 'images/Beer-cheer.png',
    },
    {
        original: 'images/money.png',
    },
];

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#56B52A',
            dark: '#00B500',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
});

class Apps extends Component {
    render() {
        /*window.scrollTo(0, 0)*/
        return <div>

            <HeroSection
                title="Apps"
                imgpath="images/Frau-mit-Smartphone.jpg"
            />
            <div className="content">


                <Imagetext
                    title="Budeturnier"
                    subtitle="Web | App | Responsive"
                    bgcolor="#cccccc"
                    interval="10000"
                    images={[
                        {
                            original: 'images/betz-group_landingpage_mobile_smartphone.png',
                        },
                        {
                            original: 'images/betz-group_landingpage_ipad.png'
                        },
                    ]}>
                    <p>Mit diesen Schritten können Sie die Applikation Smartphone installieren</p>
                    <p>Android:</p>
                    <ol>
                        <li>Öffnen sie den Google Chrome Browser</li>
                        <li>Rufen sie die Budeturnier Seite auf</li>
                        <li>Öffnen Sie die Liste oben rechts</li>
                        <li>Wählen sie <strong>Budeturnier installieren</strong> aus</li>
                        <li>Folgen Sie den Anweisungen</li>
                    </ol>

                    <p>Iphone/ Ipad:</p>
                    <ol>
                        <li>Öffnen sie den Saferi Browser</li>
                        <li>Rufen sie die Budeturnier Seite auf</li>
                        <li>Wahlen Sie das Symbol <i class="fas fa-external-link-alt"></i> aus</li>
                        <li>Wählen sie <strong>Zum Home-Bildschirm hinzufügen</strong> aus</li>
                        <li>Bestätigen Sie durch Hinzufügen den Vorgang</li>
                        <li>Die App wurde nun als App auf ihrem Smartphone installiert</li>
                    </ol>
                    <p>Viel Spaß beim ausprobieren!</p>
                </Imagetext>

            </div>

        </div>;
    }
}
export default Apps;