import React, { Component } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { TextField, Button, FormControl, FormControlLabel, FormHelperText, Checkbox } from '@material-ui/core';
import axios from 'axios';
import Swal from 'sweetalert2'

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#56B52A',
            dark: '#00B500',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
});



class EmailKontakt extends React.Component {
    constructor(props) {
        super(props);
        this.addFormData = this.addFormData.bind(this);
    }





    //Form Submission
    addFormData(evt) {
        evt.preventDefault();
        const fd = new FormData();
        fd.append('vorname', document.getElementById('vorname').value);
        fd.append('nachname', document.getElementById('nachname').value);
        fd.append('email', document.getElementById('email').value);
        fd.append('telefonnummer', document.getElementById('telefonnummer').value);
        fd.append('nachricht', document.getElementById('nachricht').value);

        axios.post('https://www.betz-group.de/Backend/mail.php', fd
        ).then(res => {
            //Success alert
        }
        );

        axios.post('https://www.betz-group.de/Backend/contact.php', fd
        ).then(res => {
            //Success alert
            Swal.fire({
                title: 'soccer-tournament Kontaktformular',
                text: res.data.data,
                type: 'success',

            });
            this.myFormRef.reset();
        }
        );
    }
    render() {
        return (
            <>
                <div className="kontakt">
                    <div className="kontakt-container">
                        <div className="kontakt-header">
                            <h1>Betz-Group.de</h1>
                            <h3>Haben wir Ihr Interesse geweckt</h3>
                        </div>


                        <div className="kontakt-content">
                            <div className="kontakt-anschrift">
                                <img className="kontakt-anschrift-img" src="images/betz-group-logo.png" />
                                <p>Florian Betz</p>
                                <p>Kleines Eschle 24</p>
                                <p>88471 Baustetten</p>
                                <p>Email: florian@betz-group.de</p>
                            </div>

                            <div className="kontakt-formular">

                                <form ref={(el) => this.myFormRef = el} onSubmit={this.addFormData} >
                                    <div className="kontakt-form">
                                        <TextField
                                            className="textfield"
                                            variant="filled"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="vorname"
                                            label="Vorname"
                                            name="vorname"
                                            autoComplete="email"
                                            type="text"
                                            Validate
                                        />
                                        <TextField
                                            className="textfield"
                                            variant="filled"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="nachname"
                                            label="Nachname"
                                            name="nachname"
                                            type="text"
                                            autoComplete="email"
                                            Validate
                                        />
                                        <TextField
                                            className="textfield"
                                            variant="filled"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="telefonnummer"
                                            label="Telefonnummer"
                                            name="telefonnummer"
                                            autoComplete="email"
                                            type="number"
                                            Validate
                                        />
                                        <TextField
                                            className="textfield"
                                            variant="filled"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="email"
                                            label="E-Mail"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            Validate
                                        />
                                    </div>
                                    <TextField
                                        className="textfield"
                                        variant="filled"
                                        margin="normal"
                                        fullWidth
                                        id="nachricht"
                                        label="Nachricht"
                                        name="nachricht"
                                        autoComplete="email"
                                        type="text"
                                        multiline
                                        rows={4}
                                        error={false}
                                    />
                                    <FormControl error={true} fullWidth
                                    >
                                        <FormControlLabel
                                            required
                                            control={
                                                <Checkbox
                                                    name="checkedF"
                                                    color="secondary"
                                                    value="false"
                                                    required
                                                />
                                            }
                                            label="Hiermit stimme ich der Verarbeitung meiner persönlichen Daten zu gemäß"
                                        />
                                        <Link
                                            class='datenschutz-verweis'
                                            to='/datenschutz'
                                        >Datenschutz</Link>
                                    </FormControl>
                                    <ThemeProvider theme={theme}>
                                        <Button className="button" variant="contained" color="primary" type="submit" >Senden</Button>
                                    </ThemeProvider>
                                </form>


                            </div>


                        </div>
                    </div>
                </div >
            </>
        );
    }
}

export default EmailKontakt;




