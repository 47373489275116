import React, { Component } from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route, HashRouter } from 'react-router-dom';


import Impressum from './components/pages/Impressum';
import Datenschutz from './components/pages/Datenschutz';
import NotFound from './components/pages/NotFound';
import Footer from './components/Footer'
import Apps from './components/pages/Apps';
import Vorrunde from './components/pages/Vorrunde';
import Endrunde from './components/pages/Endrunde';





//function App() {
class App extends Component {

  render() {

    return (
      <>
        <HashRouter basename={'/'}>
          <Navbar />
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/vorrunde' component={Vorrunde} />
            <Route path='/endrunde' component={Endrunde} />
            <Route path='/impressum' component={Impressum} />
            <Route path='/datenschutz' component={Datenschutz} />
            <Route path='/apps' component={Apps} />
            <Route path="/404" component={NotFound} />
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </HashRouter >
      </>
    );
  }
}

export default App;