import React, { Component } from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import TextContainer from '../TextContainer';
import { db } from '../../Firebase';
import { Icon, Button } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';

import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Imagetext from '../Imagetext';
import ImagetextLeft from '../ImagetextLeft';
import Beautifulimage from '../Beatifulimage';


const images = [
    {
        original: 'images/harry_sport_shop.jpeg',
    },
    {
        original: 'images/klinger.jpeg',
    },
    {
        original: 'images/knoll.jpeg',
    },
    {
        original: 'images/kronenbrauerei.jpeg',
    },
    {
        original: 'images/money.png',
    },
    {
        original: 'images/schlager.jpeg',
    },
    {
        original: 'images/stoeferle.jpeg',
    },

];

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#56B52A',
            dark: '#00B500',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
});

class Vorrunde extends Component {
    render() {
        /*window.scrollTo(0, 0)*/
        return <div>

            {/*<HeroSection
                title="Apps"
                imgpath="images/Frau-mit-Smartphone.jpg"
    />*/}
            <div className="content">
                <Beautifulimage src="images/Scharfe Eck Logo Dicker.png" />


                <h1>Vorrunde</h1>


                <h3>Gruppe A</h3>
                <iframe id="widgetTable"
                    src="https://www.meinturnierplan.de/displayTable.php?id=1673354433&gr=1"
                    allowtransparency="true"
                    frameborder="0"
                    width="100%"
                    height="320" >
                    <p>
                        Dein Browser kann das Turnierwidget leider nicht darstellen.
                        <a href="https://www.meinturnierplan.de/showit.php?id=1673354433">
                            Hier geht es zum Turnier.
                        </a>
                    </p>
                </iframe>

                <h3>Gruppe B</h3>
                <iframe id="widgetTable"
                    src="https://www.meinturnierplan.de/displayTable.php?id=1673354433&gr=2"
                    allowtransparency="true"
                    frameborder="0"
                    width="100%"
                    height="320" >
                    <p>
                        Dein Browser kann das Turnierwidget leider nicht darstellen.
                        <a href="https://www.meinturnierplan.de/showit.php?id=1673354433">
                            Hier geht es zum Turnier.
                        </a>
                    </p>
                </iframe>

                <h3>Gruppe C</h3>
                <iframe id="widgetTable"
                    src="https://www.meinturnierplan.de/displayTable.php?id=1673354433&gr=3"
                    allowtransparency="true"
                    frameborder="0"
                    width="100%"
                    height="320" >
                    <p>
                        Dein Browser kann das Turnierwidget leider nicht darstellen.
                        <a href="https://www.meinturnierplan.de/showit.php?id=1673354433">
                            Hier geht es zum Turnier.
                        </a>
                    </p>
                </iframe>

                <h3>Spielplan</h3>
                <iframe id="widgetMatches"
                    src="https://www.meinturnierplan.de/displayMatches.php?id=1673354433"
                    allowtransparency="true"
                    frameborder="0"
                    width="100%"
                    height="1200"
                >
                    <p>Dein Browser kann das Turnierwidget leider nicht darstellen.
                        <a href="https://www.meinturnierplan.de/showit.php?id=1673354433">
                            Hier geht es zum Turnier.
                        </a>
                    </p>
                </iframe>

                <h3>Unsere Sponsoren</h3>
            </div>

        </div >;
    }
}
export default Vorrunde;