import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
    return (
        <div className='cards'>
            {/*<h1>Übersicht der Inhalte</h1>*/}
            <div className='cards__container'>
                <div className='cards__wrapper'>
                    <ul className='cards__items'>
                        <CardItem
                            src='images/S_scharfe_Eck_1-2.jpg.png'
                            text='Budeturnier Vorrunde'
                            label='Vorrunde'
                            path='/vorrunde'
                        />
                        <CardItem
                            src='images/S_scharfe_Eck_1-2.jpg.png'
                            text='Budeturnier Endrunde'
                            label='Endrunde'
                            path='/endrunde'
                        />
                    </ul>

                </div>
            </div>
        </div>
    );
}

export default Cards;